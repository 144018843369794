import React from 'react';

const CustomFooterPagination = (props) => { 

    return (
        <>{ props.ActionsComponent() }</>
    );
};

export default CustomFooterPagination;
